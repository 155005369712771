import { loadNewsRessorts } from "./news";

export function initNav() {
    $('.mobile-nav-toggle, .nav-toggle').on('click touchstart', function () {
        const but = $(this);
        but.toggleClass('open');
        $('#main-nav').toggleClass('open');
        if ($('#main-nav').hasClass('open')) {
            but.attr("aria-expanded", "true");
            $(document.body).one('click', () => {
                but.removeClass('open');
                $('#main-nav').removeClass('open');
                but.attr("aria-expanded", "false");
            });
        } else {
            but.attr("aria-expanded", "false");
        }
        return false;
    }).one('pointerdown', () => {
        const trendingTopicsNav = $('#toptopics-nav, #trending-nav');
        if (trendingTopicsNav.length) {
            $.get("https://www.uncovr.com/snippets/MenuTrending").then((data) => {
                $('#toptopics-nav').html(data.topHtml);
                $('#trending-nav').html(data.trendingHtml);
            });
        }

        if ($('#ressorts-nav').length) {
            loadNewsRessorts().then((d) => {
                for (let id of d.order) {
                    if(id != 3) {
                        $('#ressorts-nav').append(`
                            <li><a href="/Articles/Ressort/${encodeURIComponent(d.ressorts[id].name)}-${id}">${d.ressorts[id].name}</a></li>
                        `);
                    }
                }
                $('#ressorts-nav').append(`
                    <li><a href="/Articles/Ressort/Panorama">Panorama</a></li>
                `);
            });
        }
    });

    $('.nav-overlay button[aria-controls]').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();

        const $li = $(`#${$(e.currentTarget).attr("aria-controls")}`).parents('li');
        $li.toggleClass("group-open");
        $(e.currentTarget).attr("aria-expanded", $li.hasClass("group-open"));
    })
    $('.nav-overlay button[aria-controls][aria-expanded="true"]').each((i, el) => {
        $(`#${$(el).attr("aria-controls")}`).parents('li').addClass('group-open');
    });

    $('nav.user button[aria-controls]').on('click mouseenter', (e) => {
        e.stopPropagation();
        e.preventDefault();

        const $nav = $(`#${$(e.currentTarget).attr("aria-controls")}`).parents('nav');
        $nav.addClass("user--open");
        $(e.currentTarget).attr("aria-expanded", $nav.hasClass("user--open"));
    })
    $('nav.user').on('mouseleave', () => {
        $('nav.user').removeClass("user--open");
    })
    $(document.body).on('click', () => {
        $('nav.user--open').removeClass('user--open');
    });
}